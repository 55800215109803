import {Translations} from './translations'

export const rm : Translations = {
page: {
footer: {
save: '[Translation]',
partsList: '[Translation]',
download: {
label: '[Translation]',
withTexture: '[Translation]',
withoutTexture: '[Translation]',
},
exclVAT: '[Translation]',
orderRequest: '[Translation]',
},
header: {
undo: '[Translation]',
redo: '[Translation]',
center: '[Translation]',
closeDoors: '[Translation]',
openDoors: '[Translation]',
configure: '[Translation]',
view3D: '[Translation]',
},
items: {
construction: '[Translation]',
depth: '[Translation]',
withRolls: '[Translation]',
withSideCovers: '[Translation]',
interior: '[Translation]',
front: '[Translation]',
},
selectedItem: {
color: '[Translation]',
applyToAll: '[Translation]',
price: '[Translation]',
remove: '[Translation]',
backWall: '[Translation]',
trayReinforcement: {
name: '[Translation]',
none: '[Translation]',
upTo35kg: '[Translation]',
upTo100kg: '[Translation]',
},
withFeltMat: '[Translation]',
frontColor: '[Translation]',
},
saveDesignDialog: {
title: '[Translation]',
text: '[Translation]',
cancel: '[Translation]',
save: '[Translation]',
email: '[Translation]',
message: {
title: '[Translation]',
text: '[Translation]',
},
},
partsListDialog: {
title: '[Translation]',
total: '[Translation]',
columns: {
partNr: '[Translation]',
description: '[Translation]',
color: '[Translation]',
dimension: '[Translation]',
price: '[Translation]',
quantity: '[Translation]',
totalPrice: '[Translation]',
},
subtotal: '[Translation]',
additionalColorCost: '[Translation]',
},
orderDialog: {
title: '[Translation]',
cancel: '[Translation]',
request: '[Translation]',
labels: {
name: '[Translation]',
company: '[Translation]',
section: '[Translation]',
private: '[Translation]',
retailer: '[Translation]',
architect: '[Translation]',
email: '[Translation]',
address: '[Translation]',
zipCodeAndCity: '[Translation]',
phone: '[Translation]',
picking: '[Translation]',
notes: '[Translation]',
},
message: {
title: '[Translation]',
text: '[Translation]',
},
},
validationMessages: {
required: '[Translation]',
email: '[Translation]',
},
materialSelections: {
frameColor: '[Translation]',
accentColor: '[Translation]',
},
},
materials: {
Vernickelt: '[Translation]',
AluminiumBlank: '[Translation]',
AluminiumEloxiert: '[Translation]',
Brushed: '[Translation]',
Zinkgelb: '[Translation]',
Currygelb: '[Translation]',
Blutorange: '[Translation]',
Schwarzrot: '[Translation]',
Perlkupfer: '[Translation]',
Tannengrün: '[Translation]',
Perlweiss: '[Translation]',
Perlbeige: '[Translation]',
Beigegrau: '[Translation]',
Graubraun: '[Translation]',
Anthrazit: '[Translation]',
Tiefschwarz: '[Translation]',
Signalweiss: '[Translation]',
Schwarz: '[Translation]',
Pebble: '[Translation]',
Eiche: '[Translation]',
Multiplex: '[Translation]',
Chrome: '[Translation]',
FeltAnthrazit: '[Translation]',
BlechVerzinkt: '[Translation]',
BlechBlumigVerzinkt: '[Translation]',
WeissAluminium: '[Translation]',
LehniBlau: '[Translation]',
Mirror: '[Translation]',
Net: '[Translation]',
},
articles: {
shelf: {
SideWall: '[Translation]',
Foot: '[Translation]',
StabilityCross: '[Translation]',
Tray: '[Translation]',
Bookend: '[Translation]',
ColumnBackWall: '[Translation]',
PullOutTray: '[Translation]',
InnerTray: '[Translation]',
DiagonalTray: '[Translation]',
SlideInDoor: '[Translation]',
SlidingDoor: '[Translation]',
DoorBackWall: '[Translation]',
Drawer: '[Translation]',
SideCover: '[Translation]',
ConnectingSleeve: '[Translation]',
TableBoard: '[Translation]',
ClothesRail: '[Translation]',
TrayEnforcment: '[Translation]',
FeltMat: '[Translation]',
SideWallTopCover: '[Translation]',
RollsFrame: '[Translation]',
PreconfiguredShelf: '[Translation]',
},
wardrobe: {
ShoeLocker10: {
name: '[Translation]',
variant: '[Translation]',
},
ShoeLocker5: {
name: '[Translation]',
variant: '[Translation]',
},
ShoeLocker4: {
name: '[Translation]',
variant: '[Translation]',
},
MirrorCabinetNets: {
name: '[Translation]',
variant: '[Translation]',
},
MirrorCabinetTrays: {
name: '[Translation]',
variant: '[Translation]',
},
NetContainer: {
name: '[Translation]',
variant: '[Translation]',
},
TrayContainer: {
name: '[Translation]',
variant: '[Translation]',
},
FoldingWardrobe: {
name: '[Translation]',
},
},
},
mail: {
order: {
subject: '[Translation]',
requestText: '[Translation]',
lehniText: '[Translation]',
linkText: {
text: '[Translation]',
linkLabel: '[Translation]',
},
contact: '[Translation]',
labels: {
name: '[Translation]',
company: '[Translation]',
section: '[Translation]',
email: '[Translation]',
address: '[Translation]',
zipCodeAndCity: '[Translation]',
phone: '[Translation]',
picking: '[Translation]',
notes: '[Translation]',
},
},
partsList: {
title: '[Translation]',
depth: '[Translation]',
columns: {
partNr: '[Translation]',
description: '[Translation]',
color: '[Translation]',
dimension: '[Translation]',
price: '[Translation]',
quantity: '[Translation]',
totalPrice: '[Translation]',
},
subtotal: '[Translation]',
additionalColorCost: '[Translation]',
total: '[Translation]',
dimension: '[Translation]',
},
saveDesign: {
subject: '[Translation]',
text: '[Translation]',
linkLabel: '[Translation]',
},
},
pdf: {
linkText: {
text: '[Translation]',
linkLabel: '[Translation]',
},
partsList: {
title: '[Translation]',
depth: '[Translation]',
columns: {
partNr: '[Translation]',
description: '[Translation]',
color: '[Translation]',
dimension: '[Translation]',
price: '[Translation]',
quantity: '[Translation]',
totalPrice: '[Translation]',
},
subtotal: '[Translation]',
additionalColorCost: '[Translation]',
total: '[Translation]',
}
}
}
