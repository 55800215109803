import { render } from 'react-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from './theme'
import { LocalizationProvider, MessageBoxProvider } from '@teamsesam/core'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navigation } from './Navigation'
import { FormValidationLocalization } from './core/components/FormValidationLocalization'

const ShelfConigurator = React.lazy(() => import('./shelf/ShelfConfigurator'))
const WardrobeConigurator = React.lazy(() => import('./wardrobe/WardrobeConfigurator'))

const getLocale = () => {
  const params = new URLSearchParams(window.location.search)
  const locale = params.get('locale')
  if (locale) {
    const pathParts = locale.split('/')
    if (pathParts.includes('en')) {
      return 'en-CH'
    }
  }
  return 'de-CH'
}

render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider locale={getLocale()} currency="CHF">
      <MessageBoxProvider>
        <FormValidationLocalization />
        <CssBaseline />
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Routes>
            <Route
              path="/"
              element={
                process.env.REACT_APP_REDIRECT_ROOT_TO_SHELF === 'true' ? (
                  <React.Suspense fallback={<>loading...</>}>
                    <ShelfConigurator />
                  </React.Suspense>
                ) : (
                  <Navigation />
                )
              }
            />
            <Route
              path="/wardrobe"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <WardrobeConigurator />
                </React.Suspense>
              }
            />
            <Route
              path="/shelf"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <ShelfConigurator />
                </React.Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </MessageBoxProvider>
    </LocalizationProvider>
  </ThemeProvider>,
  document.getElementById('app'),
)
