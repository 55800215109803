import { useLocalization } from '@teamsesam/core'
import { Translations } from './translations'
import { de } from './de'
import { en } from './en'
import { rm } from './rm'

export const useTexts = (): Translations => {
  const context = useLocalization()
  if (!context.locale) {
    throw new Error('no locale defined')
  }
  switch (context.locale.substring(0, 2)) {
    case 'de':
      return de
    case 'en':
      return en
    case 'rm':
      return rm
  }
  throw new Error(`unknown locale '${context.locale}'`)
}
